import { render, staticRenderFns } from "./DocumentShow.vue?vue&type=template&id=3364fc63&scoped=true"
import script from "./DocumentShow.vue?vue&type=script&lang=js"
export * from "./DocumentShow.vue?vue&type=script&lang=js"
import style0 from "./DocumentShow.vue?vue&type=style&index=0&id=3364fc63&prod&scoped=true&lang=css"
import style1 from "./DocumentShow.vue?vue&type=style&index=1&id=3364fc63&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3364fc63",
  null
  
)

export default component.exports